import * as React from 'react';
import cn from 'classnames';
import { Collapse as RCollapse } from 'react-collapse';
import { IconArrowUp, IconArrowDown } from 'styleguide/icons';
import Grid from '../Grid/Grid';

type ItemTitleProps = {
  expanded: boolean;
  onClick: () => void;
  centered: boolean;
  fixedIcon: boolean;
  className?: string;
  iconClassName?: string;
  iconColor?: string;
  isWide?: boolean;
};

const ItemTitle = ({
  expanded,
  onClick,
  centered,
  fixedIcon,
  className,
  iconClassName,
  iconColor,
  isWide,
  children,
}: React.PropsWithChildren<ItemTitleProps>) => (
  <Grid.Container
    className={cn(
      'flex items-center cursor-pointer',
      isWide ? 'p-0' : 'md:px-5',
      centered && 'justify-center',
      className,
    )}
    onClick={onClick}
  >
    <div className={cn(fixedIcon && 'grow')}>{children}</div>
    <div className="ml-6 flex items-center">
      {expanded ? (
        <IconArrowUp color={iconColor} className={iconClassName} />
      ) : (
        <IconArrowDown color={iconColor} className={iconClassName} />
      )}
    </div>
  </Grid.Container>
);

type ItemBodyProps = {
  expanded: boolean;
  className?: string;
  isWide?: boolean;
  withPadding?: boolean;
};

const ItemBody = ({
  expanded,
  className,
  isWide,
  withPadding,
  children,
}: React.PropsWithChildren<ItemBodyProps>) => (
  <RCollapse
    isOpened={expanded}
    theme={{
      collapse: 'transition-height duration-500',
      content: cn('overflow-hidden', className, withPadding ? 'p-4' : 'md:px-5'),
    }}
  >
    <Grid.Container>
      <Grid className={cn(isWide ? '!px-0' : '')}>
        <Grid.Row>
          <Grid.Col sm={0} md={isWide ? 0 : 1} />
          <Grid.Col sm={12} md={isWide ? 12 : 10}>
            {children}
          </Grid.Col>
          <Grid.Col sm={0} md={isWide ? 0 : 1} />
        </Grid.Row>
      </Grid>
    </Grid.Container>
  </RCollapse>
);

type ItemProps = {
  body: React.ReactNode;
  title: React.ReactNode;

  // Optional state and behavior props
  expanded?: boolean;
  initiallyExpanded?: boolean;
  onToggle?: () => void;
  showMore?: boolean;

  // Optional styling props
  classNameBody?: string;
  classNameTitle?: string;
  iconClassName?: string;
  iconColor?: string;

  // Optional layout props
  centeredTitle?: boolean;
  fixedIcon?: boolean;
  isWide?: boolean;
  withPadding?: boolean;
};

const Item = ({
  body,
  title,
  expanded: controlledExpanded,
  initiallyExpanded,
  onToggle,
  showMore = false,
  classNameBody,
  classNameTitle,
  iconClassName,
  iconColor,
  centeredTitle = false,
  fixedIcon = true,
  isWide = false,
  withPadding = false,
}: ItemProps) => {
  const [expanded, setExpanded] = React.useState(!!controlledExpanded || !!initiallyExpanded);

  React.useEffect(() => {
    if (controlledExpanded === undefined) return;
    setExpanded(controlledExpanded);
  }, [controlledExpanded]);

  const toggleExpanded = () => {
    setExpanded(prev => !prev);
    if (onToggle) onToggle();
  };

  const collapseTitle = (
    <ItemTitle
      onClick={toggleExpanded}
      expanded={expanded}
      className={classNameTitle}
      centered={centeredTitle}
      isWide={isWide}
      fixedIcon={fixedIcon}
      iconClassName={iconClassName}
      iconColor={iconColor}
    >
      {title}
    </ItemTitle>
  );

  const collapseBody = (
    <ItemBody expanded={expanded} className={classNameBody} isWide={isWide} withPadding={withPadding}>
      {body}
    </ItemBody>
  );

  return showMore ? (
    <>
      {collapseBody} {collapseTitle}
    </>
  ) : (
    <>
      {collapseTitle}
      {collapseBody}
    </>
  );
};

const Collapse = ({ children, ...otherProps }: React.HTMLProps<HTMLDivElement>) => (
  <div {...otherProps}>{children}</div>
);

Collapse.Item = Item;

export default Collapse;
